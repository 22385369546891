function scrollParentToChild(parent, child) {
    if (!parent || !child) {
        return;
    }

    const parentRect = parent.getBoundingClientRect();
    const parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth,
    };

    const childRect = child.getBoundingClientRect();
    const isViewable = (childRect.top >= parentRect.top)
            && (childRect.top <= parentRect.top + parentViewableArea.height - 24);

    if (!isViewable) {
        /* eslint-disable-next-line no-param-reassign */
        parent.scrollTop = (childRect.top + parent.scrollTop) - parentRect.top;
    }
}

function setTocPosition() {
    scrollParentToChild(document.querySelector('[data-js-toc-container]'), document.querySelector('.toc li.active'));
}

// highlight first visible anchor in TOC
function highlightLinkInToc() {
    const elementsSelector = 'h2[id], h3[id]';
    const activeClassName = 'active';
    const anchorTopShiftValue = window.innerHeight * 0.25;
    const elements = document.querySelectorAll(elementsSelector);

    const elementsFiltered = [...elements].filter((i) => {
        const elTop = i.getBoundingClientRect().top - anchorTopShiftValue;

        return elTop <= 0;
    }).reverse();

    if (elementsFiltered.length) {
        const anchorId = elementsFiltered[0].getAttribute('id');
        const $tocItems = document.querySelectorAll('[data-js-table-of-contents-item]');
        const heading = document.querySelector('.toc__mobile-heading');

        const targetTocElements = [...$tocItems].filter((e) => e.querySelector('a')?.getAttribute('href') === `#${anchorId}`);

        if (targetTocElements.length) {
            $tocItems.forEach((e) => {
                e.classList.remove(activeClassName);
            });

            if (heading) {
                const index = [...$tocItems].indexOf(targetTocElements[0]);

                heading.innerHTML = `${index + 1}. ${targetTocElements[0].innerHTML}`;
            }

            targetTocElements.forEach((e) => {
                e.classList.add(activeClassName);
            });
        }
    }
}

function addTocEventListeners() {
    document.addEventListener('scroll', () => {
        document.querySelector('.toc__mobile')?.classList.add('toc__mobile_collapsed');
        highlightLinkInToc();
        setTimeout(setTocPosition, 1000);
    });

    document.addEventListener('hashchange', () => {
        highlightLinkInToc();
    });

    document.addEventListener('resize', () => {
        setTimeout(setTocPosition, 1000);
    });

    document.querySelector('[data-js-toc-mobile-toggle]')?.addEventListener('click', (event) => {
        const target = event.currentTarget as HTMLElement;

        target.closest('.toc__mobile')?.classList.toggle('toc__mobile_collapsed');
    });
}

function initTocEssentials() {
    addTocEventListeners();
    highlightLinkInToc();
}

initTocEssentials();

export {};
