import { convertDashesToCamelCase } from './text';

/**
 * Add event listener to element
 */
// eslint-disable-next-line max-len
export function on(eventName: string, elementSelector: string, handler: (event: Event, target: HTMLElement) => void): void {
    document.addEventListener(eventName, function eventHandler(e) {
        // loop parent nodes from the target to the delegation node
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        for (let { target } = e; target && target !== this; target = target.parentNode) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (target.matches(elementSelector)) {
                handler.call(null, e, target);
                break;
            }
        }
    }, false);
}

/**
 * Call function when document is ready
 *
 * @param handler
 */
export function onDomReady(handler: () => void): void {
    document.addEventListener('readystatechange', () => {
        if (document.readyState === 'complete') {
            handler();
        }
    });
}

/**
 * Return HTMLElements array from CSS selector
 *
 * @param selector CSS selector
 */
export function querySelectorAll(selector: string): HTMLElement[] {
    return [].slice.call(document.querySelectorAll(selector));
}

/**
 * Insert <script type="text/template"> content into <body> and remove it
 */
// eslint-disable-next-line max-len
export function insertTemplate(templateElement: HTMLElement | null, toElement: HTMLElement = document.body, removeTemplateAfterInsert = true): void {
    if (!templateElement) {
        return;
    }

    toElement.insertAdjacentHTML('beforeend', templateElement.innerHTML);

    if (removeTemplateAfterInsert) {
        templateElement.parentNode?.removeChild(templateElement);
    }
}

export type ObjectWithDomElementsT = {
    [id: string]: HTMLElement
};

export function queryAllWhoHasAttribute(attributeName: string): ObjectWithDomElementsT {
    const result = {};

    querySelectorAll(`[${attributeName}]`).forEach((el) => {
        const value = el.getAttribute(attributeName);

        if (value) {
            result[convertDashesToCamelCase(value)] = el;
        }
    });

    return result;
}

export function escapeCSSSelector(selector: string) {
    const symbolsToEscape = ['*', ':', '.'];
    let result = '';

    selector
        .split('')
        .forEach((symbol) => {
            let escapedSymbol = symbol;

            symbolsToEscape.forEach((symbolToEscape) => {
                escapedSymbol = escapedSymbol.replace(symbolToEscape, `\\${symbolToEscape}`);
            });

            result += escapedSymbol;
        });

    return result;
}
