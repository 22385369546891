import { COOKIE_PREFIX } from '../constants';

export const T_REFERRER_COOKIE = `${COOKIE_PREFIX}_ref`;
export const T_CLICKOUT_PLACE_COOKIE = `${COOKIE_PREFIX}_cp`;

export function isActiveCookie(name: string): boolean {
    return document.cookie.split(';').some((item) => item.trim().startsWith(`${name}=`));
}

export function getCookieValue(cookieName: string): string {
    const name = `${cookieName}=`;
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];

        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return '';
}

export function setCookie(cookieName: string, expire: number, value: string = 'true'): void {
    const d = new Date();

    d.setTime(d.getTime() + (expire * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;

    document.cookie = `${cookieName}=${value};${expires};path=/`;
}

export function setCookieValue(
    cookieName: string,
    cookieValue: string,
    crossDomain: boolean = false,
    expire: number = 0,
): void {
    let expires = '';
    let domain = '';

    if (expire) {
        const d = new Date();

        d.setTime(d.getTime() + (expire * 24 * 60 * 60 * 1000));
        expires = `expires=${d.toUTCString()};`;
    }

    if (crossDomain) {
        const currentDomain = new URL(document.location.href).host;

        domain = currentDomain.split('.').length === 2
            ? `domain=.${currentDomain};`
            : `domain=.${currentDomain.split('.').slice(1, 3).join('.')};`;
    }

    document.cookie = `${cookieName}=${cookieValue};${domain}${expires}path=/;`;
}

export function checkIfCookieEnabled() {
    let { cookieEnabled } = navigator;

    if (typeof cookieEnabled === 'undefined' && !cookieEnabled) {
        document.cookie = 'testcookie';
        cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
    }

    return cookieEnabled;
}

export function cookieAccepted(cookieName, heightCSSVariableName, button, cookiePopup, value = 'true') {
    setCookie(cookieName, parseInt(button.getAttribute('data-expire') || '', 10), value);

    document.documentElement.style.setProperty(heightCSSVariableName, '0px');
    cookiePopup?.classList.remove('cookie-policy--is-open');

    window.appWindowObject.ui.cookiePolicyBarIsVisible = false;
    window.appWindowObject.events.emit('ui:cookie-policy', 'close');
}
