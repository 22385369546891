type RoutingT = {
    setRoutingData: (routes: any) => void,
    generate: (name: string, params?: {}) => string
};

// eslint-disable-next-line import/extensions, @typescript-eslint/no-unsafe-assignment
const Routing: RoutingT = require('../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js');

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const routes = require('../../public/fos_js_routes/fos_js_routes.json');

Routing.setRoutingData(routes);

export default Routing;
