import { createNanoEvents, Emitter } from 'nanoevents';

import { GTMDataLayerItem } from './parts/tracking';

interface EventsI {
    ['ui:cookie-policy']: (action: 'open' | 'close') => void,
    ['ui:modal-visibility']: (id: string, isVisible: boolean) => void,
    ['ui:on-scroll-visibility']: (id: string, isVisible: boolean) => void,
    ['tracking:push']: (GTMDataLayerItem: GTMDataLayerItem) => void,
}

type WindowT = {
    ui: {
        currentOpenedModalId?: string | null;
        cookiePolicyBarIsVisible?: boolean;
        cookieRejectMarketing?: boolean;
    },
    events: Emitter<EventsI>,
    countriesInfo?: any,
    providersInfo?: any,
    selectedProvidersIds?: any,
    selectedCountryId?: any,
    testResults?: any,
};

const appWindowObject:WindowT = {
    ui: {
        currentOpenedModalId: null,
        cookiePolicyBarIsVisible: false,
    },
    events: createNanoEvents<EventsI>(),
    countriesInfo: null,
    providersInfo: null,
    selectedProvidersIds: null,
    selectedCountryId: null,
    testResults: null,
};

declare global {
    interface Window {
        appWindowObject: WindowT
    }
}

window.appWindowObject = appWindowObject;

export default appWindowObject;
