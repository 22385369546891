// @ts-ignore
// eslint-disable-next-line import/extensions
import { HCAPTCHA_SITE_KEY } from '../../../../../assets/ts/constants';
// @ts-ignore
// eslint-disable-next-line import/extensions
import * as PROJECT_CONSTANTS from '../../../../../assets/ts/constants';

const COOKIE_PREFIX = PROJECT_CONSTANTS?.COOKIE_PREFIX || '';
const BI_LINK_REGEX_FOR_TRACKING = PROJECT_CONSTANTS?.BI_LINK_REGEX_FOR_TRACKING || '';
const COMMENTS_PRIVACY_LINK = PROJECT_CONSTANTS?.COMMENTS_PRIVACY_LINK || '#';
const COMMENTS_TERMS_LINK = PROJECT_CONSTANTS?.COMMENTS_TERMS_LINK || '#';
const COMMENTS_API_LINK = PROJECT_CONSTANTS?.COMMENTS_API_LINK || '#';

export {
    PROJECT_CONSTANTS,
    HCAPTCHA_SITE_KEY,
    COOKIE_PREFIX,
    BI_LINK_REGEX_FOR_TRACKING,
    COMMENTS_PRIVACY_LINK,
    COMMENTS_TERMS_LINK,
    COMMENTS_API_LINK,
};
