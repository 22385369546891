let headerMobileMenuIsOpened = false;
const HeaderMobileMenuAction = document.querySelector('[data-js-header-mobile-menu-action]') as HTMLElement;
const HeaderMobileMenuActionIcon = document.querySelector('[data-js-header-mobile-menu-action-icon]');
const HeaderMobileMenuActionText = document.querySelector('[data-js-header-mobile-menu-action-caption]');
const footerNavigation = document.querySelectorAll('[data-js="footer-navigation"] .sub-menu');
const mobileNavigation = document.querySelectorAll('[data-component="mobile-navigation"] .sub-menu');

if (HeaderMobileMenuAction) {
    HeaderMobileMenuAction.addEventListener('click', () => {
        headerMobileMenuIsOpened = !headerMobileMenuIsOpened;

        const iconClass = headerMobileMenuIsOpened ? 'fa-close' : 'fa-bars';
        const caption = headerMobileMenuIsOpened
            ? HeaderMobileMenuAction?.dataset.closeCaption
            : HeaderMobileMenuAction?.dataset.openCaption;

        HeaderMobileMenuActionIcon?.classList.remove('fa-close', 'fa-bars');
        HeaderMobileMenuActionIcon?.classList.add(iconClass);

        if (HeaderMobileMenuActionText && caption) {
            HeaderMobileMenuActionText.textContent = caption;
        }

        document.body.classList.toggle('body__mobile-menu-opened');
    });
}

footerNavigation.forEach((item) => {
    item.addEventListener('click', () => {
        item.classList.toggle('sub-menu_expanded');
    });
});

mobileNavigation.forEach((item) => {
    item.addEventListener('click', () => {
        item.classList.toggle('sub-menu_expanded');
    });
});

export {};
