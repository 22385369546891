import './navigation';
import 'common/js/parts/lazy-images';
import 'common/js/parts/lazy-js';
import './toc';
import './language-switch';
// @ts-ignore
import tracking from 'common/js/parts/tracking';
import 'common/js/window';
import 'common/js/parts/cookie-policy';

tracking.init(false);
