import { checkIfCookieEnabled, isActiveCookie, cookieAccepted } from '../utils/cookie';
import { pushToDl } from './tracking';
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
import appWindowObject from '../window';

const cookieName = 'cookie_law';
const heightCSSVariableName = '--cookie-policy-height';
const buttonInFooter = document.querySelector('[data-js-cookie-consent]');

window.appWindowObject.ui.cookiePolicyBarIsVisible = !isActiveCookie(cookieName);

if (window.appWindowObject.ui.cookiePolicyBarIsVisible && checkIfCookieEnabled()) {
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.addedNodes.length > 0) {
                mutation.addedNodes.forEach((node) => {
                    const element = node as HTMLElement;

                    if (element.classList?.contains('cky-consent-container')) {
                        observer.disconnect();

                        const button = element?.querySelector('.cky-btn-accept') || null;
                        const privacyButton = element?.querySelector('.cky-policy');

                        pushToDl({
                            event: 'footerEvent',
                            eventLabel: 'organic',
                            eventCategory: 'cookieFooter',
                            eventAction: 'load',
                        });

                        document.documentElement.style.setProperty(heightCSSVariableName, 'var(--cookie-policy-inner-height)');

                        button?.addEventListener('click', (event) => {
                            event.preventDefault();

                            pushToDl({
                                event: 'footerEvent',
                                eventLabel: 'agreeButton',
                                eventCategory: 'cookieFooter',
                                eventAction: 'clicked > I Agree',
                            });

                            cookieAccepted(cookieName, heightCSSVariableName, button, element);
                        });

                        privacyButton?.addEventListener('click', () => {
                            pushToDl({
                                event: 'footerEvent',
                                eventLabel: 'privacyLink',
                                eventCategory: 'cookieFooter',
                                eventAction: 'clicked > Privacy policy',
                            });
                        });
                    }
                });
            }
        });
    });

    observer.observe(document, {
        attributes: false, childList: true, characterData: false, subtree: true,
    });
}

buttonInFooter?.addEventListener('click', (event) => {
    event.preventDefault();

    const cookieYesBtn = document.querySelector('.cky-btn-revisit-wrapper');

    // @ts-ignore
    cookieYesBtn?.click();

    pushToDl({
        event: 'footerEvent',
        eventLabel: 'optionsButton',
        eventCategory: 'cookieFooter',
        eventAction: 'clicked',
    });
});

export { heightCSSVariableName, cookieAccepted };
