/**
 * Convert string with - symbols to camelCaseString.
 * Example: 'power-on' -> 'powerOn'
 * @param str
 */
export function convertDashesToCamelCase(str: string) {
    return str.split('-').map((value, index) => {
        const word = value.toLowerCase();

        if (index === 0) {
            return word;
        }

        const firstLetter = word.slice(0, 1).toUpperCase();
        const otherLetters = word.slice(1);

        return firstLetter + otherLetters;
    }).join('');
}

/**
 * Converts string to title case
 * Example: 'power on' -> 'Power On'
 *
 * @param str
 */
export function convertToTitleCase(str: string) {
    return str.replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase()
            + word.slice(1).toLowerCase());
}

/**
 * Removes all other characters from string, leaving only letters
 * Example: 'power-on123:' -> 'poweron'
 *
 * @param str
 */
export function removeNonLetters(str: string) {
    return str.replace(/[^a-zA-Z]/g, '');
}

export default {
    convertDashesToCamelCase,
    convertToTitleCase,
    removeNonLetters,
};
