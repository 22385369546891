import { convertToTitleCase, removeNonLetters } from '../../utils/text';

const tableClass = '.content-table_in-category';

export function tableEventAction(clickOutElementSelector: string) {
    const tables = document.querySelectorAll(tableClass);

    tables.forEach((table) => {
        const links = table.querySelectorAll(clickOutElementSelector);

        links.forEach((link) => {
            let clickedText = '';

            try {
                clickedText = convertToTitleCase(link.closest('tr')?.firstElementChild?.textContent?.trim() || '');
                clickedText = removeNonLetters(clickedText);
                // eslint-disable-next-line no-empty
            } catch (err) {}

            if (clickedText.trim()) {
                link.setAttribute('data-ea', `clicked${clickedText}Link`);
            }
        });
    });
}

export default {
    tableEventAction,
};
